$(function() {

  $('.form__kitSelect.last .wpcf7-list-item').on('click',function(){
    $('.form__kit').addClass('js-active');
  });

  $('.form__kitSelect.first .wpcf7-list-item').on('click',function(){
    $('.form__kit').removeClass('js-active');
    $('select[name="kit-type-middle"]').val('0個');
    $('select[name="kit-type-big"]').val('0個');
    $('select[name="kit-type-roll"]').val('0個');
  });

  $('.form__kitSelect.second .wpcf7-list-item').on('click',function(){
    $('.form__kit').removeClass('js-active');
    $('select[name="kit-type-middle"]').val('0個');
    $('select[name="kit-type-big"]').val('0個');
    $('select[name="kit-type-roll"]').val('0個');
  });

});

